import React, {ReactNode} from 'react'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {AppProvider} from "@/Providers/AppProvider";


export type ProviderProps = {
  children: ReactNode,
  legacyWidget: boolean,
  props: any,
};
const Providers = ({children, legacyWidget, props}: ProviderProps) => {


  const siteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

  // Add more providers down here
  return <GoogleReCaptchaProvider reCaptchaKey={siteKey} useEnterprise>
    <AppProvider
      useLegacyWidget={legacyWidget}
      onboarding={props.onboarding}
    >
      {children}
    </AppProvider>
  </GoogleReCaptchaProvider>

}

export default Providers;
