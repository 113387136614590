
// Make sure to open external links in new window
setTimeout(() => {
// Start when React is ready
  document.querySelectorAll('a').forEach((el) => {
    const href = el.getAttribute('href');

    if (href && href.startsWith('http')){
      el.setAttribute('rel', 'noopener')
      el.setAttribute('target', '_blank')
    }
  })

}, 500)
