import TagManager from 'react-gtm-module'

/**
 * Google Tag Manager
 */
export default function useGoogleTagManager(){


  const gtmId = import.meta.env.VITE_GTM_ID;

  TagManager.initialize({
    gtmId: gtmId,// 'GTM-XXXXXX'
  })

}
