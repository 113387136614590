import React, {ReactNode} from 'react';
import {AppType} from "@/types/AppType";


const AppContext = React.createContext<AppType|undefined>(undefined);


const AppProvider = (props: AppType & {
  children: ReactNode;
}) => {
  const { children, ...appProps } = props;

  return (
    <AppContext.Provider value={appProps}>
      {children}
      </AppContext.Provider>
  );
}

const useApp = () => {
  return React.useContext(AppContext)
};

export { AppProvider, useApp };
